<template>
  <b-container fluid>
    <div class="card border-0 shadow mb-4">
      <caption class="d-flex align-items-center justify-content-between py-3 px-4">
        <div class="h6 mb-0 text-brown">分店信息</div>
        <b-button
          @click="showCreateModal()"
          class="btn btn-sm btn-primary rounded-10px px-4"
        >
          新創建
        </b-button>
      </caption>
      <div class="table-responsive mb-0">
        <table class="table table-hover">
          <thead class="bg-brown--light">
            <tr>
              <th class="text-nowrap">分店編號</th>
              <th class="text-center text-nowrap">分店名稱</th>
              <th class="text-center text-nowrap">分店名称(简体版本)</th>
              <th class="text-center text-nowrap">分店地址</th>
              <th class="text-center text-nowrap">分店地址(简体版本)</th>
              <th class="text-center text-nowrap">地圖連結</th>
              <th class="text-center text-nowrap">分店電話</th>
              <th class="text-center text-nowrap">創建日期</th>
              <th class="text-center text-nowrap">狀態</th>
              <th class="text-center text-nowrap">操作</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in branches.data" :key="item.id">
              <td class="align-middle text-nowrap">
                {{item.shopCode}}
              </td>
              <td class="align-middle text-center text-nowrap">{{item.nameZhHk}}</td>
              <td class="align-middle text-center text-nowrap">{{item.nameZhCn}}</td>
              <td>
                <div class="text-truncate-3">{{item.addressZhHk}}</div>
              </td>
              <td>
                <div class="text-truncate-3">{{item.addressZhCn}}</div>
              </td>
              <td>
                <a class="text-truncate-3" :href="item.link" target="_blank">{{item.link}}</a>
              </td>
              <td class="align-middle text-center text-nowrap">{{item.phone}}</td>
              <td class="align-middle text-center text-nowrap">
                <template>
                  <div v-if="item.active == true" class="text-success">啟用</div>
                  <div v-else-if="item.active == false" class="text-danger">停用</div>
                </template>
              </td>
              <td class="align-middle text-center text-nowrap">{{item.createdDate | formatDate}}</td>
              <td class="align-middle text-center">
                <b-dropdown variant="link" toggle-class="text-white text-decoration-none" no-caret>
                  <template #button-content>
                    <b-icon icon="three-dots" font-scale="1.5" class="text-dark"></b-icon>
                  </template>

                  <b-dropdown-item  @click="showEditModal(item.id)">編輯</b-dropdown-item>
                  <b-dropdown-item
                    :disabled="item.active == false"
                    v-on:click="handleDeactivate(item.id)"
                  >
                    停用
                  </b-dropdown-item>
                  <b-dropdown-item
                    :disabled="item.active == true"
                    v-on:click="handleActivate(item.id)"
                  >
                    啟用
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-on:click="handleDelete(item.id)"
                  >
                    刪除
                  </b-dropdown-item>
                </b-dropdown>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Create Branch Modal -->
    <b-modal ref="createBranchModal" centered size="lg" no-close-on-backdrop hide-header hide-footer>
      <b-alert show variant="danger" v-if="veeErrors.all().length">
        <ul class="list-unstyled mb-0">
          <li v-for="error in filterVeeErrors(veeErrors.all())" :key="error">{{ error }}</li>
        </ul>
      </b-alert>

      <b-form @submit.stop.prevent="onSubmitCreateBranch" class="p-3 p-lg-5">
        <h5 class="text-brown">分店</h5>

        <div class="mb-5">
          <div class="d-flex align-items-center justify-content-end mb-3">
            <div class="mr-2">啟用</div>
            <b-form-checkbox
              name="check-button"
              class="custom-switch--success"
              switch
              v-model="formControl.active"
              :checked="formControl.active"
            ></b-form-checkbox>
          </div>

          <b-row class="mb-3">
            <b-col sm="4">
              <label for="shopCode" class="text-brown">分店編號 <span class="text-danger">*</span></label>
            </b-col>
            <b-col sm="8">
              <b-form-input
                id="shopCode"
                class="rounded-0"
                name="shopCode"
                v-model="formControl.shopCode"
                v-validate="{ required: true, max: 100 }"
                :state="validateState('shopCode')"
                aria-describedby="input-shopCode-live-feedback"
                data-vv-as="shopCode"
              ></b-form-input>
            </b-col>
          </b-row>

          <b-row class="mb-3">
            <b-col sm="4">
              <label for="nameZhHk" class="text-brown">分店名稱 <span class="text-danger">*</span></label>
            </b-col>
            <b-col sm="8">
              <b-form-input
                id="nameZhHk"
                class="rounded-0"
                name="nameZhHk"
                v-model="formControl.nameZhHk"
                v-validate="{ required: true, max: 100 }"
                :state="validateState('nameZhHk')"
                aria-describedby="input-nameZhHk-live-feedback"
                data-vv-as="nameZhHk"
              ></b-form-input>
            </b-col>
          </b-row>

          <b-row class="mb-3">
            <b-col sm="4">
              <label for="nameZhCn" class="text-brown">分店名称(简体版本)</label>
            </b-col>
            <b-col sm="8">
              <b-form-input
                id="nameZhCn"
                class="rounded-0"
                name="nameZhCn"
                v-model="formControl.nameZhCn"
                v-validate="{ required: false, max: 100 }"
                :state="validateState('nameZhCn')"
                aria-describedby="input-nameZhCn-live-feedback"
                data-vv-as="nameZhCn"
              ></b-form-input>
            </b-col>
          </b-row>

          <b-row class="mb-3">
            <b-col sm="4">
              <label for="addressZhHk" class="text-brown">分店地址 <span class="text-danger">*</span></label>
            </b-col>
            <b-col sm="8">
              <b-form-input
                id="addressZhHk"
                class="rounded-0"
                name="addressZhHk"
                v-model="formControl.addressZhHk"
                v-validate="{ required: true, max: 100 }"
                :state="validateState('addressZhHk')"
                aria-describedby="input-addressZhHk-live-feedback"
                data-vv-as="addressZhHk"
              ></b-form-input>
            </b-col>
          </b-row>

          <b-row class="mb-3">
            <b-col sm="4">
              <label for="addressZhCn" class="text-brown">分店地址(简体版本)</label>
            </b-col>
            <b-col sm="8">
              <b-form-input
                id="addressZhCn"
                class="rounded-0"
                name="addressZhCn"
                v-model="formControl.addressZhCn"
                v-validate="{ required: false, max: 100 }"
                :state="validateState('addressZhCn')"
                aria-describedby="input-addressZhCn-live-feedback"
                data-vv-as="addressZhCn"
              ></b-form-input>
            </b-col>
          </b-row>

          <b-row class="mb-3">
            <b-col sm="4">
              <label for="link" class="text-brown">地圖連結 </label>
            </b-col>
            <b-col sm="8">
              <b-form-input
                id="link"
                class="rounded-0"
                name="link"
                v-model="formControl.link"
                v-validate="{ required: false }"
                :state="validateState('link')"
                aria-describedby="input-link-live-feedback"
                data-vv-as="link"
              ></b-form-input>
            </b-col>
          </b-row>

          <b-row class="mb-3">
            <b-col sm="4">
              <label for="phone" class="text-brown">分店電話 <span class="text-danger">*</span></label>
            </b-col>
            <b-col sm="8">
              <b-form-input
                id="phone"
                class="rounded-0"
                name="phone"
                v-model="formControl.phone"
                v-validate="{ required: true }"
                :state="validateState('phone')"
                aria-describedby="input-phone-live-feedback"
                data-vv-as="phone"
              ></b-form-input>
            </b-col>
          </b-row>

        </div>

        <div class="d-flex align-items-center justify-content-end">
          <b-button @click="hideCreateModal()" class="btn btn-warning rounded-10px px-3 px-lg-5 text-white mr-2 me-lg-4">取消</b-button>
          <b-button :disabled="!hasVeeErrors || isSubmitting" type="submit" variant="primary" class="rounded-10px px-3 px-lg-5">
            <b-spinner v-if="isSubmitting" small class="mr-2"></b-spinner
              >儲存</b-button>
        </div>
      </b-form>
    </b-modal>

    <!-- Edit Branch Modal -->
    <b-modal ref="editBranchModal" centered size="lg" no-close-on-backdrop hide-header hide-footer>
      <b-alert show variant="danger" v-if="veeErrors.all().length">
        <ul class="list-unstyled mb-0">
          <li v-for="error in filterVeeErrors(veeErrors.all())" :key="error">{{ error }}</li>
        </ul>
      </b-alert>

      <b-form @submit.stop.prevent="onSubmitUpdateBranch" class="p-3 p-lg-5">
        <h5 class="text-brown">分店</h5>

        <div class="mb-5">
          <div class="d-flex align-items-center justify-content-end mb-3">
            <div class="mr-2">啟用</div>
            <b-form-checkbox
              name="check-button"
              class="custom-switch--success"
              switch
              v-model="formControl.active"
              :checked="formControl.active"
            ></b-form-checkbox>
          </div>

          <b-row class="mb-3">
            <b-col sm="4">
              <label for="shopCode" class="text-brown">分店編號 <span class="text-danger">*</span></label>
            </b-col>
            <b-col sm="8">
              <b-form-input
                id="shopCode"
                class="rounded-0"
                name="shopCode"
                v-model="formControl.shopCode"
                v-validate="{ required: true, max: 100 }"
                :state="validateState('shopCode')"
                aria-describedby="input-shopCode-live-feedback"
                data-vv-as="shopCode"
              ></b-form-input>
            </b-col>
          </b-row>

          <b-row class="mb-3">
            <b-col sm="4">
              <label for="nameZhHk" class="text-brown">分店名稱 <span class="text-danger">*</span></label>
            </b-col>
            <b-col sm="8">
              <b-form-input
                id="nameZhHk"
                class="rounded-0"
                name="nameZhHk"
                v-model="formControl.nameZhHk"
                v-validate="{ required: true, max: 100 }"
                :state="validateState('nameZhHk')"
                aria-describedby="input-nameZhHk-live-feedback"
                data-vv-as="nameZhHk"
              ></b-form-input>
            </b-col>
          </b-row>

          <b-row class="mb-3">
            <b-col sm="4">
              <label for="nameZhCn" class="text-brown">分店名称(简体版本)</label>
            </b-col>
            <b-col sm="8">
              <b-form-input
                id="nameZhCn"
                class="rounded-0"
                name="nameZhCn"
                v-model="formControl.nameZhCn"
                v-validate="{ required: false, max: 100 }"
                :state="validateState('nameZhCn')"
                aria-describedby="input-nameZhCn-live-feedback"
                data-vv-as="nameZhCn"
              ></b-form-input>
            </b-col>
          </b-row>

          <b-row class="mb-3">
            <b-col sm="4">
              <label for="addressZhHk" class="text-brown">分店地址 <span class="text-danger">*</span></label>
            </b-col>
            <b-col sm="8">
              <b-form-input
                id="addressZhHk"
                class="rounded-0"
                name="addressZhHk"
                v-model="formControl.addressZhHk"
                v-validate="{ required: true, max: 100 }"
                :state="validateState('addressZhHk')"
                aria-describedby="input-addressZhHk-live-feedback"
                data-vv-as="addressZhHk"
              ></b-form-input>
            </b-col>
          </b-row>

          <b-row class="mb-3">
            <b-col sm="4">
              <label for="addressZhCn" class="text-brown">分店地址(简体版本)</label>
            </b-col>
            <b-col sm="8">
              <b-form-input
                id="addressZhCn"
                class="rounded-0"
                name="addressZhCn"
                v-model="formControl.addressZhCn"
                v-validate="{ required: false, max: 100 }"
                :state="validateState('addressZhCn')"
                aria-describedby="input-addressZhCn-live-feedback"
                data-vv-as="addressZhCn"
              ></b-form-input>
            </b-col>
          </b-row>

          <b-row class="mb-3">
            <b-col sm="4">
              <label for="link" class="text-brown">地圖連結 </label>
            </b-col>
            <b-col sm="8">
              <b-form-input
                id="link"
                class="rounded-0"
                name="link"
                v-model="formControl.link"
                v-validate="{ required: false }"
                :state="validateState('link')"
                aria-describedby="input-link-live-feedback"
                data-vv-as="link"
              ></b-form-input>
            </b-col>
          </b-row>

          <b-row class="mb-3">
            <b-col sm="4">
              <label for="phone" class="text-brown">分店電話 <span class="text-danger">*</span></label>
            </b-col>
            <b-col sm="8">
              <b-form-input
                id="phone"
                class="rounded-0"
                name="phone"
                v-model="formControl.phone"
                v-validate="{ required: true }"
                :state="validateState('phone')"
                aria-describedby="input-phone-live-feedback"
                data-vv-as="phone"
              ></b-form-input>
            </b-col>
          </b-row>

        </div>

        <div class="d-flex align-items-center justify-content-end">
          <b-button @click="hideEditModal()" class="btn btn-warning rounded-10px px-3 px-lg-5 text-white mr-2 me-lg-4">取消</b-button>
          <b-button :disabled="!hasVeeErrors || isSubmitting" type="submit" variant="primary" class="rounded-10px px-3 px-lg-5">
            <b-spinner v-if="isSubmitting" small class="mr-2"></b-spinner
              >儲存</b-button>
        </div>
      </b-form>
    </b-modal>

  </b-container>
</template>

<script>
import {
  getBranches,
  getBranch,
  createBranch,
  updateBranch,
  deactivateBranch,
  activateBranch,
  deleteBranch
} from '@/services/branch.service'

export default {
  name: 'Branch',

  data: () => ({
    branchId: 0,
    branches: [],
    formControl: {
      active: true,
      nameZhCn: '',
      nameZhHk: '',
      addressZhCn: '',
      addressZhHk: '',
      phone: '',
      link: '',
      shopCode: ''
    },
    isSubmitting: false
  }),

  mounted () {
    this.getAll()
  },

  computed: {
    hasVeeErrors: ({ veeErrors }) => veeErrors.all().length === 0
  },

  methods: {
    getAll () {
      getBranches().then(
        res => {
          this.branches = res.data
        },
        err => {
          this.makeToast('danger', '發生錯誤，請稍後再試。', err)
        }
      )
    },
    validateState (ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref)
      }
      return null
    },
    filterVeeErrors (veeErrors = []) {
      return veeErrors.filter((element, index) => {
        return veeErrors.indexOf(element) === index
      })
    },
    onSubmitCreateBranch () {
      this.$validator.validateAll().then(result => {
        if (!result) {
          return
        }
        this.isSubmitting = true
        createBranch(this.formControl).then(
          res => {
            if (res.data.success) {
              this.hideCreateModal()
              this.isSubmitting = false
              this.getAll()
              this.makeToast('success', '分店', '儲存成功')
            }
          },
          err => {
            this.makeToast('danger', '發生錯誤，請稍後再試。', err)
            this.isSubmitting = false
          }
        )
      })
    },
    onSubmitUpdateBranch () {
      this.$validator.validateAll().then(result => {
        if (!result) {
          return
        }
        this.isSubmitting = true
        updateBranch(this.branchId, this.formControl).then(
          res => {
            if (res.data.success) {
              this.hideEditModal()
              this.isSubmitting = false
              this.getAll()
              this.makeToast('success', '分店', '更新成功')
            }
          },
          err => {
            this.makeToast('danger', '發生錯誤，請稍後再試。', err)
            this.isSubmitting = false
          }
        )
      })
    },
    handleDeactivate (id) {
      deactivateBranch(id).then(
        res => {
          if (res.data.success) {
            this.getAll()
            this.makeToast('success', '分店', '停用成功')
          }
        },
        err => {
          this.makeToast('danger', '發生錯誤，請稍後再試。', err)
        }
      )
    },
    handleActivate (id) {
      activateBranch(id).then(
        res => {
          if (res.data.success) {
            this.getAll()
            this.makeToast('success', '分店', '啟用成功')
          }
        },
        err => {
          this.makeToast('danger', '發生錯誤，請稍後再試。', err)
        }
      )
    },
    handleDelete (id) {
      deleteBranch(id).then(
        res => {
          if (res.data.success) {
            this.getAll()
            this.makeToast('success', '分店', '刪除成功')
          }
        },
        err => {
          this.makeToast('danger', '發生錯誤，請稍後再試。', err)
        }
      )
    },
    makeToast (variant = null, title = 'Title', message = 'Message!') {
      this.$bvToast.toast(message, {
        title: title,
        autoHideDelay: 3000,
        variant: variant,
        solid: true
      })
    },

    showCreateModal () {
      this.$refs.createBranchModal.show()
    },
    hideCreateModal () {
      this.resetformControl()
      this.$refs.createBranchModal.hide()
    },
    showEditModal (id) {
      getBranch(id).then(
        res => {
          if (res.data.success) {
            this.branchId = id
            this.formControl = {
              active: res.data.data.active,
              nameZhHk: res.data.data.nameZhHk,
              nameZhCn: res.data.data.nameZhCn,
              addressZhHk: res.data.data.addressZhHk,
              addressZhCn: res.data.data.addressZhCn,
              phone: res.data.data.phone,
              link: res.data.data.link,
              shopCode: res.data.data.shopCode
            }
            this.$refs.editBranchModal.show()
          }
        },
        err => {
          this.makeToast('danger', '發生錯誤，請稍後再試。', err)
        }
      )
    },
    hideEditModal () {
      this.resetformControl()
      this.$refs.editBranchModal.hide()
    },
    resetformControl () {
      this.formControl = {
        active: true,
        nameZhCn: '',
        nameZhHk: '',
        addressZhCn: '',
        addressZhHk: '',
        phone: '',
        shopCode: ''
      }
    }
  }
}
</script>
