import http from './http-common'

export async function getBranches () {
  return await http.get('/staff/branches?sort=createdDate,desc')
}

export async function getBranch (id) {
  return await http.get(`/staff/branches/${id}`)
}

export async function createBranch (data) {
  return await http.post('/staff/branches', data)
}

export async function updateBranch (id, data) {
  return await http.put(`/staff/branches/${id}`, data)
}

export async function deactivateBranch (id) {
  return await http.put(`/staff/branches/${id}/deactivate`)
}

export async function activateBranch (id) {
  return await http.put(`/staff/branches/${id}/activate`)
}

export async function deleteBranch (id) {
  return await http.delete(`/staff/branches/${id}`)
}
